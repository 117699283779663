import * as React from 'react';
import { ButtonStyle } from './Button.styles';
import { twMerge } from 'tailwind-merge';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  name?: string;
  color?: 'primary' | 'secondary' | 'danger' | 'outline' | 'ghost' | 'outlineSecondary';
  size?: 'xs' | 'sm' | 'md' | 'lg' | undefined;
  rounded?: 'xs' | 'sm' | 'md' | 'lg' | 'full';
  prefixIcon?: JSX.Element;
  children?: React.ReactNode;
}

const Button = ({
  name,
  color = 'primary',
  size,
  rounded,
  className,
  prefixIcon,
  children,
  ...rest
}: ButtonProps): JSX.Element => {
  return (
    <button {...rest} className={twMerge(ButtonStyle({ color, size, rounded }), 'flex', className)}>
      {prefixIcon && <div className="mr-2">{prefixIcon}</div>}
      {name ?? children}
    </button>
  );
};

export default Button;
