import { yupResolver } from '@hookform/resolvers/yup';
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import FloatingDialog from 'components/FloatingDialog';
import InputV2 from 'components/InputV2';
import TextArea from 'components/TextArea';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Form, { HireFormType } from './setup-form';
import { useQuery } from '@tanstack/react-query';
import CatalogService from 'actions/catalog/catalog.api';
import SelectV2 from 'components/SelectV2';
import SearchLocation from 'components/AutocompleteLocation';
import MapComponent from 'components/Map';
import Button from 'components/Button';
import BusinessService from 'actions/business';
import useUser from 'utils/hooks/useUser';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

type ModalFormProps = {
  userId: string;
  hireAmount: number;
  open: boolean;
  setOpen: (v: boolean) => void;
  setHire: (s?: { name: string; id: string }) => void;
  onOfferHire: () => void;
};

const ModalForm = ({ userId, hireAmount, open, setOpen, setHire, onOfferHire }: ModalFormProps) => {
  const {
    business: { id },
    cognitoUser: { id: idUser },
  } = useUser();

  const [t] = useTranslation(['Hire']);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: yupResolver(Form),
  });

  const { data } = useQuery({
    queryFn: CatalogService.getHireData,
  });

  const location = form.watch('location');

  const locationMapData = useMemo(() => {
    if (
      location &&
      location.locationCoords &&
      location.locationCoords.latitude &&
      location.locationCoords.longitude
    ) {
      return {
        latitude: location.locationCoords.latitude,
        longitude: location.locationCoords.longitude,
      };
    } else {
      return {
        latitude: 39.742043,
        longitude: -104.991531,
      };
    }
  }, [location]);

  const onChangeModal = (s: boolean) => {
    if (!s) {
      setHire(undefined);
    }

    setOpen(s);
  };

  const onSubmit: SubmitHandler<HireFormType> = (data) => {
    console.log(data);
    setLoading(true);
    BusinessService.createHire({
      ...data,
      locationCoords: {
        latitude: data.location.locationCoords.latitude,
        longitude: data.location.locationCoords.longitude,
      },
      businessId: id,
      businessUserId: idUser,
      hireAmount: hireAmount,
      userId,
      startDate: data.startDate.toISOString(),
      paymentType: data.paymentType.id,
      paymentAmount: data.paymentAmount,
      location: {
        address: data.location.location.address,
        notificationsTopic: data.location.location.notificationsTopic,
        topic: data.location.location.topic,
      },
    })
      .then(() => {
        toast.success('Offer sent');
        onOfferHire();
      })
      .catch(() => {
        toast.error('Something went wrong');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <FloatingDialog setIsOpen={onChangeModal} isOpen={open} className="w-full max-w-3xl h-[90%]">
      <div className="rounded-lg shadow-md bg-white m-auto p-6 w-full max-w-3xl">
        <h2 className="text-primary text-[22px] font-bold">{t('sendOffer')}</h2>

        <form onSubmit={form.handleSubmit(onSubmit)}>
          <p className="mb-2">{t('position')}</p>
          <InputV2
            {...form.register('positionTitle')}
            placeholder={t('position')}
            errors={form.formState.errors.positionTitle}
          />

          <p className="my-2">{t('startDate')}</p>
          <Controller
            control={form.control}
            name="startDate"
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                selected={value}
                onChange={(date: Date) =>
                  onChange(DateTime.fromJSDate(date).startOf('day').toJSDate())
                }
                bgWhite
                timeCaption="date"
                dateFormat="MM/dd/yyyy"
                textInput={t('startDate')}
                minDate={new Date()}
                hideArrow
                wrapperClassName="w-full"
                customStyle="!mt-2 border border-neutral-400 text-base"
                errors={form.formState.errors.startDate}
              />
            )}
          />

          <p className="mt-2">{t('first')}</p>

          <TextArea
            {...form.register('firstDayInstructions')}
            rows={5}
            className="mt-3 w-full"
            placeholder={t('enterInstructions')}
            error={form.formState.errors.firstDayInstructions}
          />

          <p className="my-2">{t('payment')}</p>

          <div className="flex">
            <div className="w-1/5">
              <Controller
                control={form.control}
                name="paymentType"
                render={({ field: { onChange, value } }) => (
                  <SelectV2
                    showDropDownArrow
                    name="Payment"
                    items={data ?? []}
                    itemSelected={value}
                    setSelectedItem={onChange}
                    className="border-[1px] pl-2 border-neutral-400 focus:outline-secondary-400 text-neutral-1600 placeholder:text-neutral-1600 text-base rounded py-1.5 w-full"
                  />
                )}
              />
            </div>

            <InputV2
              {...form.register('paymentAmount')}
              className="ml-2"
              placeholder="Payment amount"
            />
          </div>
          <p className="mt-2">{t('location')}</p>

          <Controller
            control={form.control}
            name="location"
            render={({ field: { onChange } }) => (
              <>
                <SearchLocation
                  messageLocationAvailableShow
                  classNameInput=" font-regular text-sm sm:text-base appearance-none focus:outline outline-secondary-400 text-neutral-1600 placeholder:text-neutral-1600 rounded-lg border border-gray-300 bg-white relative focus:border-2 focus:border-secondary-400 focus:outline-secondary-400 focus:outline focus:outline-2 block w-full px-4 py-2 placeholder-primary   focus:z-20"
                  className="mt-2 font-medium text-sm sm:text-base appearance-none rounded-lg relative block w-full  border border-none bg-white  placeholder-gray-500 text-gray-900  focus:outline-secondary-400 focus:outline focus:outline-3 focus:border-2 focus:border-secondary-400 focus:z-10"
                  setValue={(data) => onChange(data)}
                  placeholder={t('enterLocation')}
                />
              </>
            )}
          />
          <div className="mt-4 rounded-xl overflow-hidden">
            <MapComponent
              whenReady={true}
              latitude={locationMapData.latitude}
              longitude={locationMapData.longitude}
            />
          </div>
          <p className="my-2">{t('jobInformation')}</p>

          <TextArea
            {...form.register('benefits')}
            rows={5}
            className="mt-3 w-full"
            placeholder={t('addJobInformation')}
            error={form.formState.errors.benefits}
          />

          <Button
            name={t('send')}
            disabled={loading}
            type="submit"
            className="w-full justify-center mt-4"
          />
        </form>
      </div>
    </FloatingDialog>
  );
};

export default ModalForm;
