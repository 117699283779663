import FloatingDialog from 'components/FloatingDialog';
import IndexPresenterDescription from './Index.Presenter';
import { DateTime } from 'luxon';
import MapComponent from 'components/Map';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import i18n from 'utils/i18n/i18n';
import { LangEnum } from 'utils/constants';

type DescriptionProps = {
  open: boolean;
  setSelected: (s?: { name: string; hireId: string }) => void;
  selected?: { name: string; hireId: string };
};

const IndexScreenDescription = ({ open, setSelected, selected }: DescriptionProps) => {
  const presenter = IndexPresenterDescription(selected?.hireId);
  const [hireTranslation] = useTranslation(['Hire']);

  const description = presenter.data?.description;

  const title = useMemo(() => {
    if (i18n.language === LangEnum.EN) {
      return `${selected?.name}'s ${hireTranslation('username')}`;
    } else {
      return `${hireTranslation('username')} ${selected?.name}`;
    }
  }, [i18n.language]);

  return (
    <FloatingDialog
      isOpen={open}
      className="bg-white w-full max-w-2xl"
      setIsOpen={(v) => {
        if (!v) setSelected(undefined);
      }}
    >
      <div>
        <h3 className="text-primary text-[22px] font-bold">{title}</h3>

        <div className="overflow-y-auto w-full">
          <p className="mt-3">{hireTranslation('position')}</p>

          <p className=" text-base font-semibold capitalize">{description?.positionTitle}</p>

          <p className="mt-3">{hireTranslation('starts')}</p>
          <p className=" text-base font-semibold">
            {DateTime.fromISO(description?.startDate ?? '').toLocaleString(DateTime.DATE_MED)}
          </p>

          <p className="mt-3">{hireTranslation('first')}</p>
          <p className=" text-base font-semibold">{description?.firstDayInstructions}</p>

          <p className="mt-3">{hireTranslation('payment')}</p>
          <p className=" text-base font-semibold">
            ${description?.paymentAmount} {description?.paymentType}
          </p>

          <p className="mt-3">{hireTranslation('benefits')}</p>
          <p className=" text-base font-semibold">{description?.benefits}</p>

          <p className="mt-3">{hireTranslation('location')}</p>
          <p className=" text-base font-semibold mb-4">{description?.location}</p>

          {description && (
            <MapComponent
              height={200}
              whenReady={true}
              latitude={description?.locationCoords.latitude}
              longitude={description?.locationCoords.longitude}
            />
          )}
        </div>
      </div>
    </FloatingDialog>
  );
};
export default IndexScreenDescription;
